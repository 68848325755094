//
// Header CSS
//

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  background: $white;
  z-index: 9;

  transition: all 0.3s;
  //box-shadow: 0 4px 4px rgba(0,0,0,.1);
  height: $headerHeightCompact;
  padding: 2px 0 0 0;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    padding-top: 10px;

    img {
      display: block;
      width: 160px;
      transition: all 0.3s
    }
  }


  &.compact {
    border-bottom: 1px solid $grey-light;
    height: $headerHeightCompact;
    padding: 2px 0 0 0;

    .logo {
      img {
        width: 160px;
      }
    }
  }


  @media only screen and (min-width: $size-M2) {
    padding: 10px 0 10px 0;
    height: $headerHeightDefault;

    .logo {
      padding-top: 15px;

      img {
        display: block;
        width: 240px;
        transition: all 0.3s
      }
    }


    &.compact {
      border-bottom: 1px solid $grey-light;
      height: $headerHeightCompact;
      padding: 2px 0 0 0;

      .logo {
        padding-top: 8px;

        img {
          width: 160px;
        }
      }
    }

  }

}
