//
// TG Supported Areas CSS
//

.support-areas {
  padding: 60px 0;
  //color: $white;

  header {
    text-align: center;
    margin-bottom: 20px;
  }

}

.support-areas-grid {
  display: flex;
  flex-flow: row wrap;
  @include ul-reset;
  margin: 0 -60px;


  li {
    padding: 20px 60px;
    width: calc(100% / 3);
  }

  h3 {
    //border: 1px solid $grey-dark;
    text-align: center;
    padding: 10px 20px;
    margin: 0;
    background: $red;
    color: $white;
  }

  @media only screen and (max-width: $size-XL3) {
    margin: 0 -10px;

    li {
      padding: 10px;
    }
  }

  @media only screen and (max-width: $size-XL) {
    h3 {
      @include font-size(16);
    }
  }

  @media only screen and (max-width: $size-M3) {
    margin: 0 -5px;

    li {
      padding: 5px;
    }
  }

  @media only screen and (max-width: $size-M2) {

    li {
      width: 50%;
    }
  }

  @media only screen and (max-width: $size-S22) {

    li {
      width: 100%;
    }
  }
}