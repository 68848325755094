//
// Footer CSS
//

.page-footer {
  background: $grey-alt;
  color: $white;
  border-top: 15px solid $red;
  line-height: 1.6;

  a {
    color: $white;
    text-decoration: none;
  }

  &-main {
    padding: 80px 0;
  }

  .flex-row {

    .col {

      &.contact {
        span {
          letter-spacing: 0;
          @include font-size($font-size-S);
        }
      }

      &.social-media {
        width: 100%;
      }
    }

  }

  &-bottom {
    background: $black;
    padding: 16px 0;
    @include font-size($font-size-S);

    .flex-row {
      display: flex;
      justify-content: space-between;
    }
  }


  @media only screen and (min-width: $size-S2) {
    .flex-row {
      display: flex;
      flex-flow: row wrap;

      .col {
        width: 49%;

        &.nav-footer-legal,
        &.nav-footer-additional {
          border-left: 1px solid $white;
          display: flex;
          justify-content: center;
        }

        &.contact {
          padding-left: 0;
          padding-right: 20px;
          padding-bottom: 20px;
          width: 100%;
        }

        &.social-media {
          //width: 28%;
        }
      }

    }
  }


  @media only screen and (min-width: $size-M) {
    .flex-row {
      flex-flow: row wrap;

      .col {
        width: 32%;

        &.contact {
          width: 32%;
          padding-bottom: 0;
        }
      }

    }
  }

  @media only screen and (min-width: $size-L3) {
    .flex-row {
      flex-flow: row wrap;

      .col {
        width: 24%;


        &.social-media {
          width: 20%;
        }
      }

    }
  }

}