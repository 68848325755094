//
// EXT: Powermail CSS
//

.tx-powermail {

  .container-fluid {
    max-width: 1000px;
    padding: 0 40px;
    margin: auto;

  }

  h3 {
    //display: none;
  }

  fieldset {
    border: none;
    margin: 0 0 0 0;
    padding: 0;

    legend {
      display: none;
    }

    .powermail_fieldwrap {
      margin-top: 15px;
    }

    .checkbox {
      > label {
        padding-top: 3px;
      }
    }

    // make fieldset flex with 50% width of child by default
    &.layout1 {
      display: flex;
      flex-flow: row wrap;
      margin: -10px 0;

      .powermail_fieldwrap {
        width: 50%;
        margin-top: 0;
        padding: 10px 15px;

        &.layout1 {
          width: 100%;
        }

        @media only screen and (max-width: $size-L) {
          width: 100%;
        }
      }

      .powermail_fieldwrap_type_check {
        > label {
          display: none;
        }
      }
    }


  }

  label {
    display: block;
  }

  input {
    border: none;
    width: 100%;
    padding: 10px;
    //border-radius: 5px;
    background: $grey-light;
    line-height: 25px;
  }

  select {
    width: 100%;
    background: $grey-light;
    padding: 10px;
    border: none;

  }

  textarea {
    border: none;
    width: 100%;
    padding: 10px;
    //border-radius: 5px;
    background: $grey-light;
  }

  .powermail_input {
  }

  .powermail_fieldwrap_type_check {
    overflow: hidden;

    .checkbox {
      position: relative;
      padding-left: 28px;

      input {
        width: auto;
        position: absolute;
        left: 2px;
        top: 6px;
      }
    }
  }

  .powermail_checkbox {

  }

  .powermail_fieldwrap_type_submit {
    margin-top: 30px;
  }

  .powermail_fieldwrap_submit {
    //padding-top: 15px;
  }

  .powermail_submit {
    background: $red;
    color: $white;
    cursor: pointer;
    //height: 24px;
    width: auto;
    padding: 5px 10px;
    font-weight: 600;
  }

  ul {
    margin: 0;
  }

  .parsley-required,
  .parsley-custom-error-message {
    @include font-size(10);
  }


  // layout 2 fieldset 50/50 3rd one 100%
  @media screen and (min-width: $size-M2) {

    fieldset {
      margin-top: 0;
    }

    form.layout3 {

      > fieldset {

        &:nth-of-type(1) {
          float: left;
          width: 50%;
          padding-right: 30px;
        }

        &:nth-of-type(2) {
          float: left;
          width: 50%;
          padding-left: 30px;
        }

        &:nth-of-type(3) {
          clear: both;
          padding-top: 20px;
        }

      }

    }

  }

  // dynamic fields add
  .form-field-dynamic-add-on {

    .powermail_field {
      position: relative;
    }

    .add-item {
      position: absolute;
      right: 10px;
      top: 12px;
      background: #dedede;
      border: 1px solid $grey-dark;

      &:hover {
        background: $grey-light;
      }
    }

  }

}