//
// Nav Main CSS
//

.nav-main {
  display: flex;
  align-items: center;

  ul {
    @include ul-reset;
    //display: none;
  }

  // mobile version
  @media only screen and (max-width: $size-S2) {
    ul {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, .9);
      padding: 10px 60px;
      transform: translateX(100%);
      transition: all 500ms;
      width: 100%;

      li {
        border-top: 1px solid $grey-mid;

        &:first-child {
          border: none;
        }


        a {
          display: block;
          color: $grey-dark;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 600;
          @include font-size(16);
          padding: 20px 0;
          text-align: right;

          &:hover {
            color: $red;
          }

        }
      }
      border-top: 1px solid $grey-dark;
      border-bottom: 1px solid $grey-dark;
    }

    &.active {
      ul {
        transform: translateX(0);
      }
    }
  }

  // desktop version
  @media only screen and (min-width: $size-S2) {
    padding-top: 10px;

    ul {
      display: flex !important;

      li {
        padding-left: 20px;
      }

      a {
        color: $grey-mid;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 600;
        @include font-size(16);

        &:hover {
          color: $red;
        }

        &.active {
          border-bottom: 2px solid $red;
        }
      }
    }
  }
}


// compact header version
.page-layout-3,
.page-header.compact {
  .nav-main {
    padding-top: 5px;
  }
}
