//
// Default Project Settings
//

$maxPageWidth: 1600px;
$maxInnerText: 1200px;
$baseFont: 'Source Sans Pro', sans-serif;

// font sizes
$font-size-XL: 32;
$font-size-L2: 22;
$font-size-L: 20;
$font-size-M: 18;
$font-size-M2: 16;
$font-size-S: 14;
$font-size-XS: 12;


// General Breakpoints
$size-XL5: 1600px;
$size-XL4: 1500px;
$size-XL3: 1200px;
$size-XL2: 1140px;
$size-XL1: 1100px;
$size-XL: 1050px;
//$size-L4: 1000px;
$size-L3: 940px;
//$size-L2: 920px;
$size-L: 840px;
$size-M3: 800px;
$size-M2: 760px;
$size-M: 700px;
$size-S3: 650px;
$size-S22: 600px;
$size-S21: 550px;
$size-S2: 500px;
$size-S1: 460px;
$size-S: 420px;
$size-XS: 380px;


$headerHeightDefault: 110px;
$headerHeightCompact: 60px;