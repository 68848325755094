//
// Global CSS
//


// apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*, *:before, *:after {
  box-sizing: inherit;
}


// ----- HTML-Elemente -----

body {
  margin: 0;
  font-family: $baseFont;
  @include font-size(15);
  letter-spacing: 1px;
  color: $grey-dark;
  font-weight: 300;
  overflow-x: hidden;
}

img {
  border-style: none;
}

h1 {
  @include font-size(32);
  font-weight: 500;
}

h2 {
  @include font-size(26);
  font-weight: 500;
}

h3 {
  @include font-size(20);
  font-weight: 400;
}

p {
  margin: 0 0 1em 0;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  text-decoration: none;
  color: $red;
}

strong,
b {
  font-weight: 600;
}

figure {
  margin: 0;
}

// set some elements responsive
img, object, embed, video {
  height: auto;
  max-width: 100%;
}

sub,
sup {
  font-size: 60%;
  padding-left: 3px;
}

// global helper classes

.inner {
  max-width: $maxPageWidth;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: $size-S3) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.btn-primary {
  display: inline-block;
  background: $red;
  border: none;
  color: $grey-dark;
  padding: 10px 40px;
  @include font-size(18);
  font-weight: 600;
  border-radius: 50px;
}

// video rwd
.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  @media (max-width: 979px) {
    margin-bottom: 20px;
  }
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// ----- helper css classes -----

.clearfix {
  @include clearfix;
}

.hide-text {
  @include hide-text;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  @include list-inline;
}

.invisible {
  display: none !important;
}

.visually-hidden {
  @include visually-hidden;
}

.no-margin {
  margin: 0 !important;
}

.right-float {
  float: right;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}