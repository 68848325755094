//
// Registration Form CSS
//

.registration-form {
  width: 100%;

  form {
    background: $white;
    padding: 30px 10px;
    margin: 0 -25px;
  }

  legend {
    padding: 0 30px 10px 30px;
    @include font-size($font-size-L2);
    font-weight: 300;
  }

  label {
    display: none;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
    display: block;
  }

  .powermail_fieldwrap_type_input,
  .form-field {
    padding: 0 10px;
    margin-top: 20px;

    &.last-row {
      //margin-top: 60px;
    }

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $grey-dark;
      padding: 5px 0;
    }

    &.upload,
    .powermail_file {
      position: relative;
      width: 100%;
      height: 40px;

      &::after {
        position: absolute;
        content: attr(data-text);
        left: 30px;
        right: 30px;
        top: 0;
        height: 38px;
        background: $white;
        z-index: 2;
        pointer-events: none;
        padding: 7px 0 0 190px;
        color: $grey-mid3;
      }

      &::before {
        position: absolute;
        top: 2px;
        left: 30px;
        content: "Lebenslauf hochladen";
        background: $red;
        color: $white;
        padding: 4px 10px;
        pointer-events: none;
        border-radius: 5px;
        z-index: 3;
      }

      input {
        position: absolute;
        top: 0;
        left: 30px;
        bottom: 0;
        width: calc(100% - 60px); //need to compensate -30px margin of fieldset
        cursor: pointer;
        padding-left: 57px;
      }
    }

    &.textarea {
      width: 100%;
    }

    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid $grey-mid2;
      padding: 5px 0;
    }

    &.terms {
      clear: both;
      float: none;
      width: auto;
      padding-top: 10px;

      label {
        position: relative;
        display: block;
        padding-left: 12px;
        font-weight: 300;
        cursor: pointer;

        &::before {
          content: "";
          width: 14px;
          height: 14px;
          border: 2px solid $red;
          position: absolute;
          left: 0;
          top: 3px;
        }

        &::after {
          position: absolute;
          left: 5px;
          top: 2px;
          content: "";
          width: 6px;
          height: 12px;
          border-right: 2px solid $red;
          border-bottom: 2px solid $red;
          transform: rotate(45deg);
          display: none;
        }
      }

      input {
        visibility: hidden;

        &:checked ~ label::after {
          display: block;
        }
      }
    }

  }

  .powermail_fieldwrap_type_file {
    clear: both;

    .powermail_field {
      position: relative;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid $grey-dark;

      &::after {
        position: absolute;
        content: attr(data-text);
        left: 30px;
        right: 30px;
        top: 0;
        height: 38px;
        background: $white;
        z-index: 2;
        pointer-events: none;
        padding: 7px 0 0 160px;
        color: $grey-mid3;
        overflow: hidden;
        white-space: nowrap;
      }

      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        content: "Lebenslauf hochladen";
        background: $red;
        color: $white;
        padding: 4px 10px;
        pointer-events: none;
        border-radius: 5px;
        z-index: 3;
      }
    }


    .powermail_file {
      position: absolute;
      top: 3px;
      left: 0;
      bottom: 0;
      //width: calc(100% - 60px); //need to compensate -30px margin of fieldset
      width: 100%;
      cursor: pointer;
      padding-left: 57px;
    }

  }

  .powermail_fieldwrap_type_check {
    clear: both;
    float: none;
    width: auto;
    padding-top: 10px;

    label {
      position: relative;
      display: block;
      padding-left: 12px;
      font-weight: 300;

      &::before {
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid $red;
        position: absolute;
        left: 0;
        top: 3px;
        pointer-events: none;
        cursor: pointer;

      }

      &::after {
        position: absolute;
        left: 5px;
        top: 2px;
        content: "";
        width: 6px;
        height: 12px;
        border-right: 2px solid $red;
        border-bottom: 2px solid $red;
        transform: rotate(45deg);
        display: none;
        pointer-events: none;
        cursor: pointer;
      }
    }

    .checkbox {
      position: relative;
    }

    input[type="checkbox"] {
      position: absolute;
      left: 0;
      top: 3px;
      z-index: 3;
      opacity: 0;

      // selector not working due to powermail markup using input inside label
      &:checked ~ label::after {
        display: block;
      }
    }
  }

  .powermail_fieldwrap_type_file,
  .powermail_fieldwrap_type_textarea {
    padding: 20px 10px 0 10px;
    clear: both;
  }

  .powermail_fieldwrap_type_check {
    padding: 0 30px;
    margin-top: 25px;
  }

  textarea {
    width: 100%;
    height: 80px;
    border: none;
    border-bottom: 1px solid $grey-mid2;
    padding: 5px 0;
  }

  .form-submit,
  .powermail_fieldwrap_type_submit {
    margin-top: 50px;
    text-align: center;

    input {
      display: inline-block;
      background: $red;
      border: none;
      color: $grey-dark;
      padding: 10px 40px;
      @include font-size(18);
      font-weight: 600;
      border-radius: 50px;
    }
  }


  .powermail_fieldwrap_anrede {
    clear: both;
    float: none;
    padding-top: 25px;

    label {
      //display: block;
      //float: left;
      //width: 50%;
      padding: 6px 30px;
      color: #888888;
    }

    .powermail_field {
      //float: left;
      width: 50%;
      padding: 0 30px;

      select {
        width: 100%;
        border: none;
        border-bottom: 1px solid $grey-dark;
        padding: 5px 0;
        color: #888888;

        option {
          color: $grey-dark;
        }

        option:first-child {
          color: #888888;
        }
      }
    }
  }

  /* not in use
  .powermail_fieldwrap_bevorzugtespaket {
    clear: both;
    float: none;
    padding-top: 25px;

    label {
      //display: block;
      //float: left;
      //width: 50%;
      padding: 6px 30px;
      color: #888888;
    }

    .powermail_field {
      //float: left;
      //width: 50%;
      padding: 0 30px;

      select {
        width: 100%;
        border: none;
        border-bottom: 1px solid $grey-dark;
        padding: 5px 0;
        color: #888888;

        option {
          color: #888888;
        }
      }
    }
  }
  */
}


// error handling
.parsley-errors-list {
  @include ul-reset;

  li {
    color: red;
    @include font-size($font-size-XS);
  }
}

.powermail_fieldwrap_type_file {

  .parsley-errors-list {
    position: absolute;
    bottom: -22px;
    z-index: 9;
  }
}

.powermail_create {
  max-width: $maxInnerText;
  margin: 50px auto;
  text-align: center;
  @include font-size($font-size-L2);
}


@media only screen and (min-width: $size-S) {

  .registration-form {

    form {
      margin: 0;
    }

  }

}


@media only screen and (min-width: $size-S21) {

  .registration-form {

    .powermail_fieldwrap_type_file,
    .powermail_fieldwrap_type_textarea {
      padding: 20px 30px 0 30px;
      clear: both;
    }

    legend {
      @include font-size($font-size-XL);
    }

    .powermail_fieldwrap_type_input,
    .form-field {
      padding: 0 30px;
      float: left;
      width: 50%;
    }

  }

}


@media only screen and (min-width: $size-XL1) {

  .registration-form {
    width: 45%;
    padding-right: 40px;
  }

}