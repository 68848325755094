//
// Marketing Stats CSS
//

.marketing-stats {
  margin: 100px 0;
  padding: 60px 0;
  text-align: center;
  background: $red;
  color: $white;

  .row {
    display: flex;
    justify-items: center;
    justify-content: center;
  }

  .stat-col {
    padding: 0 60px;
    //color: $white;
  }

  .marketing-stats-value {
    @include font-size(30);
    font-weight: 700;
  }

  .marketing-stats-label {
    @include font-size(24);
  }


  @media only screen and (max-width: $size-XL) {
    .stat-col {
      padding: 0 30px;
    }
  }

  @media only screen and (max-width: $size-M) {
    .stat-col {
      &:last-child {
        display: none;
      }
    }
  }

  @media only screen and (max-width: $size-S2) {
    .stat-col {
      &:first-child {
        display: none;
      }
    }
  }

}