//
// Burger Icon CSS
//

.burger-icon-area {
  position: absolute;
  top: 15px;
  //padding: 15px 20px 20px 20px;
  cursor: pointer;
  z-index: 9;
}

.burger-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  //padding: 15px 20px 20px 20px;
  cursor: pointer;
  z-index: 9;
  background: none;
  border: none;
  padding: 10px 0;
  //display: flex;

  span.icon {
    display: block;
    position: relative;
    height: 4px;
    background-color: $grey-dark;
    width: 38px;
    margin-top: 10px;
    transition-delay: 0.4s, 0.5s;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $grey-dark;
      content: "";
      transition-duration: 0.3s, 0.3s;
      transition-delay: 0.3s, 0s;
    }

    &::before {
      top: -10px;
      transition-property: top, transform;
    }

    &::after {
      bottom: -10px;
      transition-property: bottom, transform;
    }
  }

  span.text {
    display: block;
    text-transform: uppercase;
    color: $white;
    @include font-size(12);
    line-height: 1;
    padding: 16px 0 0 0;
    font-weight: 600;
  }

  &.active {
    span.icon {
      background: none !important;
      //transition-delay: 0.3s, 0.3s;
      transition-delay: 0ms;

      &::before {
        top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.3s;
      }
      &::after {
        bottom: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.3s;
      }
    }
  }


  @media (min-width: $size-S3) {
    right: 40px;
  }


  @media only screen and (min-width: $size-S2) {
    display: none;
  }

}
